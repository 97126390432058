import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';

interface FloatingButtonProps {
  remix: {
    canvas: string;
  };
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ remix }) => {
  console.log('FloatingButton rendered with remix:', remix); // Debugging line

  const handleButtonClick = () => {
    amplitude.track('go to create remix from remix detail');
    window.location.href = `/canvas/${remix.canvas}`;
  };

  return (
    <button 
      type="button" 
      onClick={handleButtonClick}
      className="btn btn-dark choose-form-button show-button"
      style={{
        position: 'absolute',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000
      }}
    >
      Create Your Own Remix ✨
    </button>
  );
};

export default FloatingButton;