import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CanvasDetail.css';


interface Product {
  id: number;
  name: string;
  description: string;
  price: string;
  image: string;
}

interface BaseProductSelectWithImagesProps {
  baseProductIds: number[];
  handleBaseProductChange: (id: string) => void;
}

const BaseProductSelectWithImages: React.FC<BaseProductSelectWithImagesProps> = ({ baseProductIds, handleBaseProductChange }) => {
  const [baseProducts, setBaseProducts] = useState<Product[]>([]);
  const [selectedBaseProduct, setSelectedBaseProduct] = useState('');
  const active_url = process.env.REACT_APP_FLOSSY_API_URL;

    

  const fetchBaseProducts = async () => {
    const token = 'a24e549e62d35efe610192dd98355e96e1888ceb'; // replace with your actual token
    if (baseProductIds && baseProductIds.every(id => id !== undefined)) {
      const fetchedBaseProducts = await Promise.all(baseProductIds.map(async (id) => {
        const response = await axios.get(`${active_url}/baseproduct/${id}/`, {
          headers: { 'Authorization': `Token ${token}` }
        });
        return { ...response.data, image: response.data.image };
      }));
      setBaseProducts(fetchedBaseProducts);
    }
  };

  useEffect(() => {
    fetchBaseProducts();
  }, [baseProductIds]);

  return (
    <div>
      {baseProducts.map((product) => (
        <div 
          key={product.id} 
          onClick={() => { handleBaseProductChange(String(product.id)); setSelectedBaseProduct(String(product.id)); }}
          className={`product-container ${selectedBaseProduct === String(product.id) ? 'product-container-selected' : 'product-container-not-selected'}`}
        >
          <img src={product.image} alt={product.name} />
          {/* <div className="artist-description" style={{ textAlign: 'center' }}><p>{product.name}</p></div>  */}
        </div>
      ))}
    </div>
  );
};

export default BaseProductSelectWithImages;