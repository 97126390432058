import React from 'react';
import './ProgressBar.css';

interface ProgressBarProps {
  step: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ step }) => {
  let title = '';
  let percentage = '0%';

  switch (step) {
    case 0:
      title = 'Step 0: start';
      percentage = '0%';
      break;
    case 1:
      title = 'Step 1: Create Artwork';
      percentage = '15%';
      break;
    case 2:
      title = 'Step 2: Choose Item';
      percentage = '60%';
      break;
    // Add more cases as needed
    default:
      break;
  }

  return (
    <div className="progress-bar">
      <div className="title">{title}</div>
      <div className="bar">
        <div className="fill" style={{ width: percentage }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;