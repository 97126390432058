import React, { useEffect } from 'react';
import RemixDetail from '../components/RemixDetail';
import * as amplitude from '@amplitude/analytics-browser';

const RemixDetailView = () => {
  useEffect(() => {
    amplitude.track('Remix Detail Page Viewed');
  }, []);

  return (
    <div>
      <h1></h1>
      <RemixDetail />
    </div>
  );
};

export default RemixDetailView;