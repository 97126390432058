
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import '../components/CanvasDetail.css';
import * as amplitude from '@amplitude/analytics-browser';


interface Share {
  image_url: string;
  share_url: string;
  // Add other properties of share if there are any
}

function SharePage() {
  const { id } = useParams();
  const [share, setShare] = useState<Share | null>(null);
  const active_url = process.env.REACT_APP_FLOSSY_API_URL;
  const urlRef = useRef<HTMLInputElement>(null);

  const handleCopy = () => {
    if (urlRef.current) {
      urlRef.current.select();
      document.execCommand('copy');
    }
  };

  useEffect(() => {
    amplitude.track('Share Page Viewed');

    // Fetch the share data from the backend
    axios.get(`${active_url}/share/${id}`)
      .then(response => setShare(response.data));
  }, [id, active_url]);

  if (!share) {
    return <div>Loading...</div>;
  }


  return (
    <Container fluid style={{height: '100vh' }}>
    <Row> 
      <Col xl={{ span: 4, offset: 4 }} lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }}  sm={{ span: 10, offset: 1 }}  xs={12}>
      
          <Row style={{ marginTop: '40px' }}>
           </Row> 
          <Row style={{ marginTop: '10px' }}>
            <img src={share.image_url} alt="Shared content" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 10px 10px 70px 10px', padding:0, borderRadius:'10px'  }} />
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col xs={9} style={{margin:'0', padding:'0'}}>
              <input ref={urlRef} type="text" value={share.share_url} readOnly style={{ width: '100%', textAlign: 'center',  border: '1px solid black',   padding: '5px',   backgroundColor: 'white',   fontSize: '14px',   color: 'black', borderRadius:'4px'}} />
              
            </Col>
            <Col xs={3} style={{margin:'0', padding:'0'}}>
              <button className={'btn btn-dark'}  onClick={handleCopy} style={{width:'100%',fontSize:'12px'}}>Copy link</button>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
            <div>
            <iframe 
              style={{borderRadius:'12px'}} 
              src="https://open.spotify.com/embed/track/13sYq1DRwEvWqfimRKRUAC?utm_source=generator&theme=0" 
              width="100%" 
              height="152" 
              frameBorder="0" 
              allowFullScreen 
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
              loading="lazy">
            </iframe>
            </div>
        
        </Row>
      </Col>
    </Row>
  </Container>
  );
}

export default SharePage;

// To do - get the image ID from the URL Or store in local storage
//  Use that image ID to make the