import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Overlay, Popover } from 'react-bootstrap';

interface ShareButtonProps {
  fabricCanvas: {
    toDataURL: () => string;
  };
  canvasTitle: string | null;
  canvasUser: string | null;
}

interface Data {
  share_url: string;
  imageId: string;
  // add other properties as needed
}

const ShareButton: React.FC<ShareButtonProps> = ({ fabricCanvas, canvasTitle, canvasUser }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [data, setData] = useState<Data | null>(null);
  const target = useRef(null);
  const active_url = process.env.REACT_APP_FLOSSY_API_URL;
  const shareText = `I just made a remix of ${canvasUser}'s ${canvasTitle} on Flossy`;
  const client_url = process.env.REACT_APP_CLIENT_DOMAIN;


  const handleShare = () => {
    const canvasImage = fabricCanvas.toDataURL();

    axios.post(`${active_url}/share/`, {
      image: canvasImage,
    })
      .then(response => {
        setData(response.data);

        if (navigator.share) {
          navigator.share({
            title: canvasTitle || 'Untitled',
            text: shareText,
            url: data?.share_url,
            files: [new File([canvasImage], 'canvasImage.png', { type: 'image/png' })]
          })
          .catch((error) => console.log('Error sharing', error));
        } else {
          setPopoverVisible(true);
        }
      });
  };

  const popover = data && (
    <Popover id="popover-basic" className="popover-style share-popover">
      <Popover.Body>
      <small style={{ color: 'white' }}>Here is your unique link</small> <br></br>
        <small><a href="#" onClick={(e) => {
          e.preventDefault();
          window.open(`/share/${data.imageId}`, '_blank');
        }}>
          <span style={{ color: 'white' }}>
          {client_url + '/share/' + data.imageId}
          </span>
          {data.share_url}
        </a></small>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <button ref={target} className={'btn btn-dark btn-download'} onClick={handleShare} onBlur={() => setPopoverVisible(false)}>Share Design</button>

      {popover && (
        <Overlay
          show={popoverVisible}
          target={target.current}
          placement="bottom"
          rootClose={true}
          onHide={() => setPopoverVisible(false)}
        >
          {popover}
        </Overlay>
      )}
    </>
  );
};

export default ShareButton;