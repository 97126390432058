import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Col, Card } from 'react-bootstrap'; // Import Col here
import './CanvasList.css'; // Import the CSS


interface Canvas {
  id: number;
  note: string;
  title: string;
  base_product: string;
  cover_image_url: string;
  // include other properties here
}

const CanvasList: React.FC = () => {
  const [canvases, setCanvases] = useState<Canvas[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const active_url = process.env.REACT_APP_FLOSSY_API_URL;


  useEffect(() => {
    setIsLoading(true);
    const token = 'a24e549e62d35efe610192dd98355e96e1888ceb'; // replace 'your_token' with your actual token
    axios.get(`${active_url}/canvases/`, {
      headers: { 'Authorization': `Token ${token}` }
    })
      .then(response => {
        setCanvases(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (canvases.length === 0) {
    return <div>No canvases created yet.</div>;
  }

  return (
    <div className="row">
      {canvases.map(canvas => (
        <Col md={3} key={canvas.id}>
          <Card className="mb-3"> {/* Use Card here */}
            <Card.Img variant="top" src={decodeURIComponent(canvas.cover_image_url)} alt="Canvas" />
            <Card.Body className="text-center"> {/* Add the text-center class here */}
              <Card.Text><small className="text-muted">{canvas.title}</small></Card.Text>
              <Link to={`/canvas/${canvas.id}/remixes`}>View Remixes</Link>
              <Link to={`/canvas/${canvas.id}`} key={canvas.id}> Create Remix </Link>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </div>
  );
}

export default CanvasList;

