// src/pages/Canvases.jsx
import React from 'react';
import CanvasList from '../components/CanvasList';

// import Button from '../components/Button'; // Make sure the path is correct

function Canvases() {
  return (
    <div>
      <h1>Canvases Page</h1>
      <CanvasList />
    </div>
  );
}

export default Canvases;