// src/pages/Return.tsx
import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../components/CanvasDetail.css';
import {Link } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';




const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerCountry, setCustomerCountry] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [shippingPrice, setShippingPrice] = useState('');
  const [shippingRate, setShippingRate] = useState('');
  const [remixID, setremixID] = useState('')
  const [baseProductID, setbaseProductID] = useState('')
  const [canvasID, setcanvasID] = useState('')
  const [size, setSize] = useState('')
  const [currency, setCurrency] = useState('')
  const [stripePaymentIntentId, setStripePaymentIntentId] = useState('');
  const [orderCreated, setOrderCreated] = useState(false); // New state variable
  const [flossyOrderId, setFlossyOrderId] = useState(null); // New state variable
  const active_url = (process.env.REACT_APP_FLOSSY_API_URL || '').replace('/api', '');



  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    amplitude.track('Successful Checkout Page Viewed');

    fetch(`${active_url}/session-status?session_id=${sessionId}`)
    .then((res) => res.json())
    .then((data) => {
      setStatus(data.status);
      setCustomerEmail(data.customer_details.email);
      setCustomerName(data.customer_details.name);
      setCustomerAddress(data.shipping_details.address);
      setTotalPrice(data.amount_total);
      setStripePaymentIntentId(data.payment_intent);
      setCustomerCountry(data.customer_details.address.country);
      setShippingPrice(data.shipping_cost.amount_total);
      setShippingRate(data.shipping_cost.shipping_rate);
      setremixID(data.metadata.remix_id);
      setbaseProductID(data.metadata.base_product_id);
      setcanvasID(data.metadata.canvas_id);
      setSize(data.metadata.size);
      setCurrency(data.metadata.currency)
    })
    .catch((error) => {
      console.log('Fetch error:', error);
    });
  }, []);
  const [orderFetchCalled, setOrderFetchCalled] = useState(false);

  useEffect(() => {
    if (status === 'complete' && !orderCreated && !orderFetchCalled) {
      // Create a new order
      setOrderFetchCalled(true);

    // Convert totalPrice and shippingPrice to numbers
    const totalPriceNum = parseFloat(totalPrice);
    const shippingPriceNum = parseFloat(shippingPrice);

    // Calculate the new product price
    const newProductPrice = (totalPriceNum - shippingPriceNum).toString();

      const orderData = {
        customer_email: customerEmail,
        customer_name: customerName,
        customer_address: customerAddress,
        shipping_price: shippingPrice,
        product_price: newProductPrice,
        stripe_payment_intent_id: stripePaymentIntentId,
        shipping_method: shippingRate,
        customer_country: customerCountry,
        remix: remixID,
        base_product: baseProductID,
        size: size,
        currency: currency
      };

      fetch(`${active_url}/api/order/`, {
        
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      })
      .then((res) => res.json())
      .then((orderData) => {
        setOrderCreated(true); // Set orderCreated to true after the order is created
        setFlossyOrderId(orderData.flossy_order_id); // Set the flossyOrderId state variable
      })
      .catch((error) => {
        console.log('Error creating order:', error);
        setOrderFetchCalled(false);

      });
    }
  }, [status, customerEmail, customerName, customerAddress, totalPrice, stripePaymentIntentId, orderCreated, size]); // Add orderCreated to the dependencies array

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    console.log('status changed to complete');

    return (
        <Container fluid style={{height: '100vh' }}>
        <Row> 
            <Col xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} xs={12} sm={12}>
            <Row style={{ marginTop: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Image 
                    src={'https://s3-flossyapp.s3.eu-north-1.amazonaws.com/static/green_success_icon.png'} 
                    alt="Remix image" 
                    roundedCircle 
                    style={{ width: '100px' }}
                />  

                <div className="artist-name" style={{textAlign:'center'}}>Order Confirmed </div> 
                <div className="artist-description" style={{textAlign:'center'}}>Reference Number: {flossyOrderId}</div> 
            </Row>
            <Row>
              <section id="success">                
               </section>
               <div className="description-box">  
               <div className="artist-instagram-link">
                    <a className="link-badge" href="#">🎉 in-progress 🎉</a>
                </div>               
                      <p>
                        Your order is now being processed. Our team will make sure your design is suitable before its approved for fulfillment.
                    </p>
                    <p>
                        We&apos;ll email you on {customerEmail} when it&apos;s approved, manufactured and shipped.
                    </p>
                    <p className="description-header">
                        If you have any questions, please email <a href="mailto:gam@flossy.ai">gam@flossy.ai</a>
                        </p>

                    </div>
            </Row>
            <Row style={{ marginTop: '20px' }}>
            <Link to={`/canvas/${canvasID}/remixes`} className="btn btn-dark button">
              Back to community
            </Link>
             </Row>
           </Col>
        </Row>
        </Container>
    )
  }

  return null;
}

export default Return;