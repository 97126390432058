import React, { useEffect } from 'react';
import CanvasDetail from '../components/CanvasDetail';
import * as amplitude from '@amplitude/analytics-browser';


function Canvases() {
  useEffect(() => {
    amplitude.track('Canvas Detail Page Viewed');
  }, []);

  return (
    <div>
      <CanvasDetail />
    </div>
  );
}

export default Canvases;